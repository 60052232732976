<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <section class="chat-app-window">
      <div class="active-chat">
        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header class="chat-header">
            <!-- Avatar & Name -->
            <div class="d-flex align-items-center">
              <!-- Toggle Icon -->
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon
                  icon="MenuIcon"
                  class="cursor-pointer"
                  size="21"
                />
              </div>

              <b-avatar size="36" class="mr-1 cursor-pointer badge-minimal" />
              <h6 class="mb-0">Admin</h6>
            </div>

            <!-- Contact Actions -->
            <div class="d-flex align-items-center">
              <feather-icon
                icon="RefreshCwIcon"
                size="17"
                @click="refresh"
                class="cursor-pointer d-sm-block d-none mr-1"
              />
            </div>
          </header>
        </div>
        <div
          v-if="activeChat.length === 0"
          class="start-chat-area text-center my-4"
        >
          <div class="mb-1 start-chat-icon">
            <feather-icon icon="MessageSquareIcon" size="56" />
          </div>
          <h4 class="sidebar-toggle start-chat-text">Start Conversation</h4>
        </div>
        <div v-else>
          <!-- User Chat Area -->
          <vue-perfect-scrollbar
            ref="refChatLogPS"
            :settings="perfectScrollbarSettings"
            class="user-chats scroll-area"
          >
            <chat-log :chat-data="activeChat" />
          </vue-perfect-scrollbar>
        </div>

        <!-- Message Input -->
        <b-form class="chat-app-form" @submit.prevent="sendMessage">
          <b-input-group class="input-group-merge form-send-message mr-1">
            <b-form-input
              v-model="chatInputMessage"
              placeholder="Enter your message"
            />
          </b-input-group>
          <b-button variant="primary" type="submit"> Send </b-button>
        </b-form>
      </div>
    </section>
  </div>
</template>

<script>
import { BAvatar, BForm, BInputGroup, BFormInput, BButton } from "bootstrap-vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import errorResponse from "@/libs/axiosError"
import axios from "@/libs/axios"
import VuePerfectScrollbar from "vue-perfect-scrollbar"
import ChatLog from "./ChatLog.vue"

export default {
  components: {
    // BSV
    BAvatar,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,

    // 3rd Party
    VuePerfectScrollbar,

    // SFC
    ChatLog,
  },
  data() {
    return {
      assignmentData: {},
      activeChat: [],
      profileUserDataMinimal: {},
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
      chatInputMessage: "",
    }
  },
  mounted() {
    this.getMessageData()
  },
  methods: {
    refresh() {
      this.getMessageData()
    },
    async getMessageData() {
      if (
        this.$route.params.candidate_assignments_id &&
        this.$route.params.assignment_id
      ) {
        axios
          .post(`/admin/candidate_assignment/chats`, {
            candidate_assignments_id:
              this.$route.params.candidate_assignments_id,
            assignment_id: this.$route.params.assignment_id,
          })
          .then((res) => {
            this.activeChat = res.data
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Candidate Chat`,
                icon: "CoffeeIcon",
                variant: "success",
                text: `You have successfully fetched Candidate Chats!`,
              },
            })
          })
          .catch((error) => {
            errorResponse(error, this.$router)
          })
      } else {
        this.$router.push({
          path: `/`,
        })
      }
    },
    async sendMessage() {
      if (this.chatInputMessage.length !== 0) {
        axios
          .post(`/admin/candidate_assignment/chats/add`, {
            candidate_assignments_id:
              this.$route.params.candidate_assignments_id,
            assignment_id: this.$route.params.assignment_id,
            message: this.chatInputMessage,
          })
          .then(() => {
            this.chatInputMessage = ""
            this.getMessageData()
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Candidate Chat`,
                icon: "CoffeeIcon",
                variant: "success",
                text: `You have successfully Messaged Candidate!`,
              },
            })
          })
          .catch((error) => {
            errorResponse(error, this.$router)
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
